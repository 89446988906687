<template>
  <div class="formula">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="900"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <Row>
          <Col span="11">
            <FormItem label="季节" prop="season">
              <Select v-model="form.season">
                <Option
                  v-for="(item, index) in season"
                  :value="item.season"
                  :key="index"
                  >{{ item.seasonName }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="品种" prop="categoryId">
              <Select v-model="form.categoryId">
                <Option
                  v-for="item in category"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="粪污类型" prop="manureProcess">
              <Select v-model="form.manureProcess">
                <Option
                  v-for="item in excrementType"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="标准值" prop="standardValue">
              <Input v-model="form.standardValue"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="动物系数" prop="animalCoefficient">
              <Input v-model="form.animalCoefficient"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="动物单位名称" prop="animalUnitName">
              <Input v-model="form.animalUnitName"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="水系数" prop="waterCoefficient">
              <Input v-model="form.waterCoefficient"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="水单位名称" prop="waterUnitName">
              <Input v-model="form.waterUnitName"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="日期系数" prop="dateCoefficient">
              <Input v-model="form.dateCoefficient"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="日期单位名称" prop="dateUnitName">
              <Input v-model="form.dateUnitName"></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "季节",
            minWidth: 100,
            key: "seasonName",
          },
          {
            title: "品种",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "粪污类型",
            minWidth: 100,
            key: "manureProcessName",
          },
          {
            title: "标准值",
            minWidth: 100,
            key: "standardValue",
          },
          {
            title: "动物系数",
            minWidth: 100,
            key: "animalCoefficient",
          },
          {
            title: "动物单位名称",
            minWidth: 100,
            key: "animalUnitName",
          },
          {
            title: "水系数",
            minWidth: 100,
            key: "waterCoefficient",
          },
          {
            title: "水单位名称",
            minWidth: 100,
            key: "waterUnitName",
          },
          {
            title: "日期系数",
            minWidth: 100,
            key: "dateCoefficient",
          },
          {
            title: "日期单位名称",
            minWidth: 100,
            key: "dateUnitName",
          },
          {
            title: "操作",
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  <a onClick={() => this.edit(row)}>编辑</a>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        season: "",
        categoryId: "",
        manureProcess: "",
        standardValue: "",
        animalCoefficient: "",
        animalUnitName: "",
        waterUnitName: "",
        waterCoefficient: "",
        dateUnitName: "",
        dateCoefficient: "",
      },
      rules: {
        season: [{ required: true, message: "请选择季节" }],
        categoryId: [{ required: true, message: "请选择品种" }],
        manureProcess: [{ required: true, message: "请选择粪污类型" }],
        standardValue: [
          { required: true, message: "请填写标准值" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的标准值",
          },
        ],
        animalCoefficient: [
          { required: true, message: "请填写动物系数" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的动物系数",
          },
        ],
        animalUnitName: [{ required: true, message: "请填写动物单位名称" }],
        waterUnitName: [{ required: true, message: "请填写水单位名称" }],
        waterCoefficient: [
          { required: true, message: "请填写水系数" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的水系数",
          },
        ],
        dateUnitName: [{ required: true, message: "请填写日期单位名称" }],
        dateCoefficient: [
          { required: true, message: "请填写日期系数" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的日期系数",
          },
        ],
      },
      season: [],
      category: [],
      excrementType: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增排水公式配置",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "季节",
              component: "select",
              field: "season",
              data: this.season,
              defaultValue: "",
              parameterField: "season",
              showField: "seasonName",
            },
            {
              conditionName: "品种",
              component: "select",
              field: "categoryId",
              data: this.category,
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "粪污类型",
              component: "select",
              field: "manureProcess",
              data: this.excrementType,
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增排水公式配置",
        submitLoading: false,
      };
    },

    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.FORMULA.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getSeason() {
      this.$post(this.$api.SEASON_CONFIG.LIST).then((res) => {
        this.season = res;
      });
    },
    getCategory() {
      this.$post(this.$api.PRODUCT_INFO.LIST, {
        type: "2",
      }).then((res) => {
        this.category = res.list;
      });
    },
    getExcrementType() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "25",
      }).then((res) => {
        this.excrementType = res.list;
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑排水公式配置",
        submitLoading: false,
      };
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        season: "",
        categoryId: "",
        manureProcess: "",
        standardValue: "",
        animalCoefficient: "",
        animalUnitName: "",
        waterUnitName: "",
        waterCoefficient: "",
        dateUnitName: "",
        dateCoefficient: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.modal.submitLoading = true;
        this.$post(this.$api.FORMULA.ADD, {
          ...this.form,
        })
          .then(() => {
            this.modal.show = false;
            this.getList();
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getSeason();
    this.getCategory();
    this.getExcrementType();
  },
};
</script>

<style lang="less" scoped>
.formula {
  width: 100%;
  height: 100%;
}
</style>